import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Textarea as ChakraTextarea,
} from '@chakra-ui/react';
import { useField } from 'formik';
import React from 'react';

export const Textarea = ({
  name,
  label,
  placeholder,
  textareaProps,
  wrapperProps,
}) => {
  const [field, meta] = useField(name);

  const hasError = Boolean(meta.error && meta.touched);

  return (
    <FormControl isInvalid={hasError} {...wrapperProps}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <ChakraTextarea
        id={name}
        placeholder={placeholder}
        _placeholder={{ color: '#253238' }}
        border='2px solid #FFFFFF'
        borderRadius='20px'
        resize='none'
        minHeight='150px'
        maxW='749px'
        w='100%'
        {...field}
        {...textareaProps}
      />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
