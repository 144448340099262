import { useField, useFormikContext } from 'formik';
import React from 'react';
import { TextFieldView } from './text-field-view';



const TextField = ({ name, value, onChange, ...props }) => {
  if (name) return <TextFieldForm name={name} {...props} />;

  return (
    <TextFieldView name={name} value={value} onChange={onChange} {...props} />
  );
};

const TextFieldForm = ({ name, ...props }) => {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  const hasError = Boolean(meta.error && meta.touched);

  return (
    <TextFieldView
      name={name}
      value={field.value}
      onChange={(value) => setFieldValue(name, value)}
      hasError={hasError}
      errorMessage={meta.error}
      {...props}
    />
  );
};

export default TextField;