import React from 'react';

function PhoneIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='40'
      height='40'
      fill='none'
      viewBox='0 0 40 40'
    >
      <path
        fill='#1B1B1B'
        d='M11.182 28.82A42.9 42.9 0 0014.74 32c4.764 3.8 10.239 6.475 16.057 7.818a7.013 7.013 0 007.87-3.707l.925-1.849a3.925 3.925 0 00-1.484-5.097l-5.888-3.532a3.903 3.903 0 00-5.36 1.34l-1.022 1.703a.778.778 0 01-.96.324C18.763 26.553 13.446 21.236 11 15.124a.778.778 0 01.324-.96l1.702-1.023a3.903 3.903 0 001.34-5.359l-3.532-5.888A3.924 3.924 0 005.74.41l-1.85.924a7.012 7.012 0 00-3.707 7.87c1.691 7.328 5.495 14.11 11 19.615zm20.234-1.847l5.887 3.532a2.354 2.354 0 01.89 3.058l-.41.821-9.96-5.978.378-.63a2.341 2.341 0 013.215-.803zM4.588 2.73l1.85-.924a2.355 2.355 0 013.057.89l3.533 5.889a2.34 2.34 0 01-.805 3.215l-.63.379-3.538-5.9a.781.781 0 10-1.34.805l3.549 5.917a2.332 2.332 0 00-.714 2.702c1.269 3.172 3.315 6.225 5.919 8.828 2.603 2.603 5.656 4.65 8.828 5.918.977.391 2.067.089 2.704-.715l10.068 6.043a5.454 5.454 0 01-5.921 2.518C25.536 37 20.304 34.393 15.819 30.839a40.74 40.74 0 01-3.665-3.283C7.069 22.42 3.34 15.94 1.705 8.854A5.455 5.455 0 014.588 2.73z'
        opacity='0.3'
      ></path>
    </svg>
  );
}

export default PhoneIcon;
