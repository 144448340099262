import * as MESSAGES from '../messages';
import * as yup from 'yup';

export const feedbackSchema = yup.object().shape({
  subject: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
  name: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
  email: yup
    .string()
    .email(MESSAGES.EMAIL_MESSAGE)
    .required(MESSAGES.REQUIRE_MESSAGE),
  message: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
});
