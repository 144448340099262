import {
  FormControl,
  FormErrorMessage,
  Input,
} from '@chakra-ui/react';

import React from 'react';

export const TextFieldView = ({
  name = '',
  value,
  onChange,
  placeholder,
  hasError,
  errorMessage,
  inputProps,
  wrapperProps,
}) => {
  const handleChangeInput = event => {
    if (typeof onChange === 'function') {
      onChange(event.currentTarget.value);
    }
  };

  const inputOptions = {
    value,
    onChange: handleChangeInput,
    id: name,
    placeholder,
    ...inputProps,
  };

  return (
    <FormControl
      isInvalid={hasError}
      isDisabled={inputProps?.isDisabled}
      maxW={{base: 'unset', lg: '236px', md: '180px'}}
      w={{base: '95vw', md: '100%'}}
      h='48px'
      {...wrapperProps}
    >
      <Input
        _placeholder={{ color: '#253238' }}
        border='2px solid #FFFFFF'
        focusBorderColor='gray.100'
        errorBorderColor='red.300'
        borderRadius='50px'
        fontFamily='Mulish'
        color='#253238'
        {...inputOptions} 
      />
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};
