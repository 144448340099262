import GoogleMapPinIcon from '../../images/GoogleMapPinIcon';
import GoogleMapReact from 'google-map-react';
import { Icon } from '@chakra-ui/react';
import React from 'react';

const Marker = () => {
  return (
    <div>
      <Icon as={GoogleMapPinIcon} fontSize='40px' color='secondary.400' />
    </div>
  );
};

export const GoogleMap = ({ height = 450, lat, lng, zoom, apiKey }) => {
  if (lat === undefined || lng === undefined) return null;

  return (
    <div
      style={{
        minHeight: height,
        height,
        width: '100%',
        overflow: 'hidden',
      }}
    >
      <GoogleMapReact
        bootstrapURLKeys={{
          key: apiKey,
        }}
        yesIWantToUseGoogleMapApiInternals
        defaultCenter={{
          lat: parseFloat(lat),
          lng: parseFloat(lng),
        }}
        defaultZoom={zoom}
      >
        <Marker lat={lat} lng={lng} />
      </GoogleMapReact>
    </div>
  );
};
