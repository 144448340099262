import * as React from 'react';

import { Box, Button, Flex, Stack, Text, VStack } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { gql, useMutation } from '@apollo/client';

import EmailIcon from '../images/EmailIcon';
import { GoogleMap } from '../components/google-map';
import IDEImage from '../images/IDEImage';
import Layout from '../components/layout';
import PhoneIcon from '../images/PhoneIcon';
import PlacePinIcon from '../images/PlacePinIcon';
import Seo from '../components/seo';
import { StaticImage } from 'gatsby-plugin-image';
import TextField from '../components/text-field';
import { Textarea } from '../components/text-area';
import { feedbackSchema } from '../validation/schemas/feedback.schema';
import { graphql } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { useToast } from '@chakra-ui/react';

const CREATE_MESSAGE = gql`
  mutation createMessage($data: MessageInput!) {
    createMessage(data: $data) {
      data {
        id
        attributes {
          subject
          name
          email
          message
          createdAt
          updatedAt
        }
      }
    }
  }
`;

const ContactUsPage = ({
  data: {
    strapi: {
      contact: { data },
    },
  },
}) => {
  const breakpoints = useBreakpoint();
  const toast = useToast();

  const [createMessage] = useMutation(CREATE_MESSAGE);

  const handleFormSubmit = (values, { resetForm }) => {
    createMessage({
      variables: {
        data: {
          subject: values.subject,
          name: values.name,
          email: values.email,
          message: values.message,
        },
      },
      onCompleted: response => {
        toast({
          title: 'Message sent.',
          description: 'Thank you for your message.',
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
      },
      onError: () => {
        toast({
          title: 'Error sending message.',
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
      },
    });
    resetForm();
  };

  const initialValues = {
    subject: '',
    name: '',
    email: '',
    message: '',
  };

  return (
    <Layout>
      {breakpoints.xl ? (
        <Flex position='absolute' top='0' zIndex={-1}>
          <StaticImage
            placeholder='blurred'
            alt='bg'
            src='../images/TopBG.svg'
            quality={100}
            width={8400}
          />
        </Flex>
      ) : null}
      <Seo title='Contact Us' />
      <Flex alignItems='center' flexDirection='column' mt='75px'>
        <Text
          textTransform='uppercase'
          letterSpacing='0.03em'
          fontFamily='Poppins'
          fontWeight='black'
          lineHeight='109%'
          color='#303056'
          fontSize={{ base: '44px', md: '82px', sm: '60px' }}
        >
          {data.attributes.page.title}
        </Text>
        <Text
          textTransform='uppercase'
          letterSpacing='0.485em'
          fontFamily='Poppins'
          fontWeight='medium'
          lineHeight='109%'
          fontSize={{ base: '16px', md: '24px', sm: '20px' }}
          color='white'
        >
          {data.attributes.page.subtitle}
        </Text>
      </Flex>
      <Flex
        justifyContent='center'
        mt={{ base: '16', xl: '44' }}
        mb={{ base: '14', xl: '24' }}
        mx={{ base: '0', md: '6', xl: 'unset' }}
      >
        <Flex
          flexDirection='column'
          maxW={749}
          mr={{ base: 'unset', xl: '147px', md: '50px' }}
        >
          <Text
            textAlign={{ base: 'center', lg: 'unset' }}
            fontSize={{ base: '30px', md: '32px' }}
            textTransform='capitalize'
            fontWeight='normal'
            fontFamily='Sora'
            lineHeight='120%'
            color='white'
          >
            {data.attributes.contactUs.title}
          </Text>
          <Text
            textAlign={{ base: 'center', lg: 'unset' }}
            fontSize={{ base: '16px', sm: '18px' }}
            mx={{ base: '2', lg: 'unset' }}
            fontFamily='Mulish'
            fontWeight='normal'
            lineHeight='170%'
            color='#253238'
            maxW='747px'
            mt='7'
          >
            {data.attributes.contactUs.description}
          </Text>
          <Flex
            alignItems='center'
            justifyContent={{ base: 'center', lg: 'unset' }}
          >
            <Formik
              initialValues={initialValues}
              onSubmit={handleFormSubmit}
              validationSchema={feedbackSchema}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <Stack
                    spacing='5'
                    mt='10'
                    direction={{ base: 'column', md: 'row' }}
                  >
                    <TextField name='subject' placeholder={'Subject'} />
                    <TextField name='name' placeholder={'Name'} />
                    <TextField name='email' placeholder={'Email'} />
                  </Stack>
                  <Textarea
                    name='message'
                    placeholder={'Your message'}
                    wrapperProps={{
                      mt: { base: '6', xl: '6', md: '12' },
                      mb: '5',
                    }}
                  />
                  <Flex justifyContent={{ base: 'center', md: 'unset' }}>
                    <Button type='submit' variant='contact' w='138px' h='48px'>
                      <Text
                        fontFamily='Mulish'
                        fontWeight='black'
                        lineHeight='18px'
                        fontSize='14px'
                        opacity='0.8'
                        color='white'
                      >
                        CONTACT
                      </Text>
                    </Button>
                  </Flex>
                </Form>
              )}
            </Formik>
          </Flex>
        </Flex>
        {breakpoints.md ? (
          <Flex flexDirection='column' mt='16'>
            <Flex>
              <PhoneIcon />
              <VStack ml='3.5' align='flex-start'>
                <Text
                  textTransform='uppercase'
                  fontFamily='Mulish'
                  fontWeight='bold'
                  lineHeight='170%'
                  fontSize='14px'
                  color='white'
                >
                  Phone
                </Text>
                <Text
                  fontFamily='Mulish'
                  fontWeight='normal'
                  lineHeight='170%'
                  fontSize='16px'
                  color='black'
                >
                  {data.attributes.phone}
                </Text>
              </VStack>
            </Flex>

            {/* EMAIL BLOCK */}

            {/* <Box w='262px' h='1px' my='7' bgColor='white' opacity='0.1' />
            <Flex>
              <EmailIcon />
              <VStack ml='3.5' align='flex-start'>
                <Text
                  textTransform='uppercase'
                  fontFamily='Mulish'
                  fontWeight='bold'
                  lineHeight='170%'
                  fontSize='14px'
                  color='white'
                >
                  Email
                </Text>
                <Text
                  fontFamily='Mulish'
                  fontWeight='normal'
                  lineHeight='170%'
                  fontSize='16px'
                  color='black'
                >
                  {data.attributes.email}
                </Text>
              </VStack>
            </Flex> */}
            <Box w='262px' h='1px' my='7' bgColor='white' opacity='0.1' />
            <Flex>
              <PlacePinIcon />
              <VStack ml='3.5' align='flex-start'>
                <Text
                  textTransform='uppercase'
                  fontFamily='Mulish'
                  fontWeight='bold'
                  lineHeight='170%'
                  fontSize='14px'
                  color='white'
                >
                  Address
                </Text>
                <Text
                  fontFamily='Mulish'
                  fontWeight='normal'
                  lineHeight='170%'
                  fontSize='16px'
                  color='black'
                  maxW='212px'
                >
                  {data.attributes.address}
                </Text>
              </VStack>
            </Flex>
          </Flex>
        ) : null}
      </Flex>
      {!breakpoints.md ? (
        <Flex align={'center'} flexDirection='column'>
          <Flex flexDirection='column' mb='10' align={'flex-start'}>
            <Flex>
              <PhoneIcon />
              <VStack ml='3.5' align='flex-start'>
                <Text
                  textTransform='uppercase'
                  fontFamily='Mulish'
                  fontWeight='bold'
                  lineHeight='170%'
                  fontSize='14px'
                  color='white'
                >
                  Phone
                </Text>
                <Text
                  fontFamily='Mulish'
                  fontWeight='normal'
                  lineHeight='170%'
                  fontSize='16px'
                  color='black'
                >
                  {data.attributes.phone}
                </Text>
              </VStack>
            </Flex>

            {/* EMAIL BLOCK */}

            {/* <Box w='262px' h='1px' my='7' bgColor='white' opacity='0.1' />
            <Flex>
              <EmailIcon />
              <VStack ml='3.5' align='flex-start'>
                <Text
                  textTransform='uppercase'
                  fontFamily='Mulish'
                  fontWeight='bold'
                  lineHeight='170%'
                  fontSize='14px'
                  color='white'
                >
                  Email
                </Text>
                <Text
                  fontFamily='Mulish'
                  fontWeight='normal'
                  lineHeight='170%'
                  fontSize='16px'
                  color='black'
                >
                  {data.attributes.email}
                </Text>
              </VStack>
            </Flex> */}
            <Box w='262px' h='1px' my='7' bgColor='white' opacity='0.1' />
            <Flex>
              <PlacePinIcon />
              <VStack ml='3.5' align='flex-start'>
                <Text
                  textTransform='uppercase'
                  fontFamily='Mulish'
                  fontWeight='bold'
                  lineHeight='170%'
                  fontSize='14px'
                  color='white'
                >
                  Address
                </Text>
                <Text
                  fontFamily='Mulish'
                  fontWeight='normal'
                  lineHeight='170%'
                  fontSize='16px'
                  color='black'
                  maxW='212px'
                >
                  {data.attributes.address}
                </Text>
              </VStack>
            </Flex>
          </Flex>
        </Flex>
      ) : null}
      <GoogleMap
        lat={data.attributes.map.lat}
        lng={data.attributes.map.lng}
        zoom={data.attributes.map.zoom}
        apiKey={data.attributes.map.apiKey}
      />
      {breakpoints.xl ? (
        <>
          <Flex alignItems='center' flexDirection='column' mt='40'>
            <VStack alignItems='center'>
              <Text
                textTransform='uppercase'
                fontFamily='Montserrat'
                letterSpacing='0.27em'
                fontWeight='medium'
                lineHeight='10%'
                fontSize={
                  breakpoints.full ? '55px' : breakpoints.xl2 ? '40px' : '35px'
                }
                color='#37404A'
              >
                ENJOY THE SERVICES PROVIDED BY
              </Text>
              <Text
                textTransform='uppercase'
                fontFamily='Montserrat'
                letterSpacing='0.27em'
                fontWeight='medium'
                lineHeight='40%'
                fontSize={
                  breakpoints.full ? '55px' : breakpoints.xl2 ? '40px' : '35px'
                }
                color='#DD4078'
              >
                OUR TEAM OF PROFESSIONAL PROGRAMMERS
              </Text>
            </VStack>
            <Box mt='24' mb='44' w={840} h={548}>
              <IDEImage />
            </Box>
          </Flex>
        </>
      ) : null}
    </Layout>
  );
};

export default ContactUsPage;

export const query = graphql`
  query {
    strapi {
      contact {
        data {
          attributes {
            address
            email
            phone
            contactUs {
              description
              title
            }
            page {
              subtitle
              title
            }
            map {
              apiKey
              lat
              lng
              zoom
            }
          }
        }
      }
    }
  }
`;
