import React from 'react';

function PlacePinIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='40'
      height='40'
      fill='none'
      viewBox='0 0 35 41'
    >
      <path
        fill='#fff'
        d='M15.5 0C7.47 0 .936 6.534.936 14.565c0 7.182 4.981 16.596 9.16 23.228a.916.916 0 101.55-.976C4.307 25.17 2.767 18.287 2.767 14.565 2.767 7.544 8.48 1.83 15.501 1.83c7.02 0 12.733 5.713 12.733 12.734 0 11.402-13.324 28.785-13.458 28.96a.916.916 0 001.45 1.12c.565-.732 13.84-18.053 13.84-30.08C30.066 6.534 23.532 0 15.5 0z'
        opacity='0.3'
      ></path>
      <path
        fill='#fff'
        d='M15.5 9.92a.916.916 0 000-1.83 6.483 6.483 0 00-6.476 6.475A6.483 6.483 0 0015.5 21.04a6.483 6.483 0 006.476-6.476 6.419 6.419 0 00-1.315-3.913.916.916 0 00-1.459 1.108c.617.812.943 1.782.943 2.805a4.65 4.65 0 01-4.645 4.644 4.65 4.65 0 01-4.644-4.644A4.65 4.65 0 0115.5 9.92z'
        opacity='0.3'
      ></path>
    </svg>
  );
}

export default PlacePinIcon;
